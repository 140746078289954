import '../cssAll/murid/BerandaMurid.css';
import vektorProfile from "../assets/vektorProfile.svg";
import { Icon } from '@iconify/react';
import React, { useState, useEffect } from 'react';

function NavbarMurid(props){
    const showDetail = () => {
        const detailProfile = document.querySelector('.detail-profile');
        detailProfile.style.transform = 'translateX(0px)';
    }

    const showNotif = () => {
        const detailNotification = document.querySelector('.detail-notif');
        detailNotification.style.transform = 'translateX(0px)';
    }

    const {textNavigasi} = props;

    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        function handleScroll() {
        if (window.pageYOffset > 0) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
        }

        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    }, []);
    return(
        <div>
            <nav>
                <div className="navbar">
                    <h1>{textNavigasi}</h1>
                    <div className="nav-right">
                        <div className="img-profile" style={{ cursor: "pointer" }}>
                            <img src={vektorProfile} alt="img-profile" onClick={showDetail} />
                        </div>
                        <div className="btn-notification" style={{ cursor: "pointer" }} onClick={showNotif}>
                            <Icon icon="mdi:bell-notification-outline" width="24"/>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default NavbarMurid