import "../cssAll/murid/DetailTugas.css";
import { useNavigate, useParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useState, useEffect } from "react";
import NavbarMurid from "../component/NavbarMurid";
import IconNugasyuk from "../assets/IconNugasyuk.svg";
import vektorProfile from "../assets/vektorProfile.svg";
import ImgLogout from "../assets/68582-log-out.gif";
import passIcon from "../assets/pass-icon.svg";
import mataIcon from "../assets/icon-mata.svg";
import axios from "axios";

function DetailTask() {
  const navigate = useNavigate();

  const closeDetail = () => {
    const detailProfile = document.querySelector(".detail-profile");
    detailProfile.style.transform = "translateX(350px)";
  };

  const closeDetailNotif = () => {
    const detailProfile = document.querySelector(".detail-notif");
    detailProfile.style.transform = "translateX(350px)";
  };

  const showLogoutPopup = () => {
    const popupLogout = document.querySelector("#popup-logout");
    popupLogout.style.display = "flex";
    popupLogout.style.animation = "slide-down 0.3s ease-in-out";
  };

  const closeLogoutPopup = () => {
    const popupLogout = document.querySelector("#popup-logout");
    setTimeout(() => (popupLogout.style.display = "none"), 250);
    popupLogout.style.animation = "slide-up 0.3s ease-in-out";
  };

  const showForgetPopup = () => {
    const popupForget = document.querySelector("#popup-forget");
    popupForget.style.display = "flex";
    popupForget.style.animation = "slide-down 0.3s ease-in-out";
  };

  const closeForgetPopupAndClearInput = () => {
    const popupForget = document.querySelector("#popup-forget");
    setTimeout(() => (popupForget.style.display = "none"), 250);
    popupForget.style.animation = "slide-up 0.3s ease-in-out";
    const clearpassword = document.querySelector(
      "#password",
      "#newPassword",
      "#confirmPassword"
    );
    clearpassword.value = "";
    const clearpasswordNew = document.querySelector("#newPassword");
    clearpasswordNew.value = "";
    const clearpasswordConfirm = document.querySelector("#confirmPassword");
    clearpasswordConfirm.value = "";
  };

  const [passwordType, setPasswordType] = useState("password");
  const [passwordTypeNew, setPasswordTypeNew] = useState("password");
  const [passwordTypeConfirm, setPasswordTypeConfirm] = useState("password");

  function togglePasswordVisibility() {
    setPasswordType(passwordType === "password" ? "text" : "password");
  }

  function togglePasswordVisibilityNew() {
    setPasswordTypeNew(passwordTypeNew === "password" ? "text" : "password");
  }

  function togglePasswordVisibilityConfirm() {
    setPasswordTypeConfirm(
      passwordTypeConfirm === "password" ? "text" : "password"
    );
  }

  const saveToken = sessionStorage.getItem("token");

  const [dataDetailTugas, setDataDetailTugas] = useState([]);

  const [isLoading, setisLoading] = useState(false);
  const [isError, setisError] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    getDetail();
  }, [id]);

  function getDetail() {
    axios
      .get("https://www.nugasyuk.my.id/api/murid/matapelajaran/tugas/" + id, {
        headers: {
          Authorization: `Bearer ${saveToken}`,
        },
      })
      .then((response) => {
        setDataDetailTugas(response.data.data);
        setisLoading(false);
      })
      .catch((error) => console.error(error));
  }

  if (isLoading)
    return (
      <div id="load">
        <div>.</div>
        <div>.</div>
        <div>.</div>
        <div>.</div>
      </div>
    );
  else if (dataDetailTugas && !isError)
    return (
      <div>
        <aside>
          <h1
            className="title-form-login"
            onClick={() => navigate("/murid/berandamurid")}
          >
            <img src={IconNugasyuk} alt="" className="icon-nugasyuk" />
            nugasyuk
          </h1>
          <ul>
            <li onClick={() => navigate("/murid/berandamurid")}>
              <Icon icon="iconoir:home-simple" width="20" />
              Beranda
            </li>
            <li onClick={() => navigate("/murid/pagetugas")}>
              <Icon
                icon="fluent:clipboard-bullet-list-rtl-20-regular"
                width="25"
              />
              Tugas
            </li>
            <li onClick={() => navigate("/murid/pagekbm")}>
              <Icon icon="uiw:date" width="18" />
              Jadwal KBM
            </li>
            <li className="active" onClick={() => navigate("/murid/pagemapel")}>
              <Icon icon="fluent-mdl2:education" width="18" />
              Mata Pelajaran
            </li>
            <li onClick={() => navigate("/murid/pagekonseling")}>
              <Icon icon="ph:apple-podcasts-logo-duotone" width="18" />
              Konseling
            </li>
          </ul>
        </aside>
        <div className="container-content">
          {dataDetailTugas.map((detailTugas) => (
            <NavbarMurid textNavigasi={detailTugas.nama_mapel} />
          ))}
          <div className="main">
            {dataDetailTugas &&
              dataDetailTugas.map((detailTugas) => (
                <div className="con-content-detail-task">
                  <div className="content-detail-task">
                    <div className="content-detail-task-left">
                      <div className="icon-detail-task">
                        <Icon icon="uiw:time-o" width="30" />
                      </div>
                      <div className="desc-material">
                        <p className="name-task ">{detailTugas.nama_tugas}</p>
                        <p className="teacher">{detailTugas.nama_guru}</p>
                      </div>
                    </div>
                    <div className="content-detail-task-right">
                      <p className="date-upload">{detailTugas.date}</p>
                    </div>
                  </div>
                  <p className="desc-content-detail-task">{detailTugas.soal}</p>
                  <p className="task-deadline-time">
                    Deadline : <span>{detailTugas.deadline}</span>
                  </p>
                  <div className="submition-task">
                    <p className="title-submition">Pengumpulan Tugas</p>
                    <div className="file-task"></div>
                    <button className="btn-add-task">
                      <Icon icon="ic:round-plus" width="20"></Icon>
                      <p>Tambah</p>
                    </button>
                    <button className="btn-submit-task">
                      <p>Kirim</p>
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className="popup-forget" id="popup-forget">
          <form action="" className="detail-forget-password">
            <div className="navbar-detail-forget">
              <Icon
                icon="radix-icons:cross-circled"
                width="30"
                style={{ cursor: "pointer" }}
                onClick={closeForgetPopupAndClearInput}
              />
              <h2>Ganti Password</h2>
            </div>
            <p className="judul-form">Sandi lama</p>
            <div className="con-form-password">
              <img src={passIcon} alt="" />
              <input
                type={passwordType}
                id="password"
                placeholder="*********"
                className="input-password"
              />
              <button
                type="button"
                className="btn-mata"
                onClick={togglePasswordVisibility}
              >
                <img src={mataIcon} alt="" />
              </button>
            </div>
            <p className="judul-form">Sandi baru</p>
            <div className="con-form-password">
              <img src={passIcon} alt="" />
              <input
                type={passwordTypeNew}
                id="newPassword"
                placeholder="*********"
                className="input-password"
              />
              <button
                type="button"
                className="btn-mata"
                onClick={togglePasswordVisibilityNew}
              >
                <img src={mataIcon} alt="" />
              </button>
            </div>
            <p className="judul-form">Konfirmasi sandi baru</p>
            <div className="con-form-password">
              <img src={passIcon} alt="" />
              <input
                type={passwordTypeConfirm}
                id="confirmPassword"
                placeholder="*********"
                className="input-password"
              />
              <button
                type="button"
                className="btn-mata"
                onClick={togglePasswordVisibilityConfirm}
              >
                <img src={mataIcon} alt="" />
              </button>
            </div>

            <button type="submit" className="btn-simpan">
              Simpan sandi baru
            </button>
          </form>
        </div>

        <div className="detail-profile">
          <div className="content-detail">
            <div className="navbar-detail">
              <Icon
                icon="radix-icons:cross-circled"
                width="30"
                style={{ cursor: "pointer", color: "#4b4b4b" }}
                onClick={closeDetail}
              />
              <h2>Profil</h2>
            </div>
            <div className="detail-image-profile">
              <img src={vektorProfile} alt="" className="detail-img-profile" />
            </div>
            <p className="judul-detail">Email</p>
            <p className="value-detail">zumarramadhan@smkrus.sch.id</p>
            <p className="judul-detail">Nama Pengguna</p>
            <p className="value-detail">Zumar</p>
            <p className="judul-detail">Nama</p>
            <p className="value-detail">Muhammad Zumar Ramadhan</p>
            <p className="judul-detail">Jurusan</p>
            <p className="value-detail">PPLG</p>
            <p className="judul-detail">Kelas</p>
            <p className="value-detail">11 PPLG 1</p>
            <p className="judul-detail">NIS</p>
            <p className="value-detail">04449</p>
          </div>
          <div className="con-btn-detail-profile">
            <button
              className="forget-password"
              id="btn-forget-pass"
              onClick={showForgetPopup}
            >
              <Icon icon="material-symbols:key-outline-rounded" width="30" />
              <p>Ganti Password</p>
            </button>
            <button
              className="logout"
              id="btn-logout"
              onClick={showLogoutPopup}
            >
              <Icon icon="material-symbols:logout-rounded" width="30" />
              <p>Logout</p>
            </button>
          </div>
        </div>

        <div className="detail-notif">
          <div className="content-detail-notif">
            <div className="navbar-detail-notif">
              <Icon
                icon="radix-icons:cross-circled"
                width="30"
                style={{ cursor: "pointer", color: "#4b4b4b" }}
                onClick={closeDetailNotif}
              />
              <h2>Notifikasi</h2>
            </div>
            <p className="day">Hari Ini</p>
            <div className="notif">
              <div className="icon-notif">
                <Icon icon="tabler:clipboard-text" width="30" />
              </div>
              <div className="content-notif">
                <div className="name-notif">
                  <p>Application Letter</p>
                </div>
                <div className="teacher">
                  <p>Budiono, S.Pd</p>
                </div>
              </div>
            </div>
            <div className="notif">
              <div className="icon-notif">
                <Icon icon="tabler:clipboard-text" width="30" />
              </div>
              <div className="content-notif">
                <div className="name-notif">
                  <p>Sejarah Gojek</p>
                </div>
                <div className="teacher">
                  <p>Rini, S.Pd</p>
                </div>
              </div>
            </div>
            <div className="notif">
              <div className="icon-notif">
                <Icon icon="ri:book-line" width="30" />
              </div>
              <div className="content-notif">
                <div className="name-notif">
                  <p>Sejarah Gojek</p>
                </div>
                <div className="teacher">
                  <p>Rini, S.Pd</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default DetailTask;
